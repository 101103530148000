import '/assets/styles/modals/search.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';


// Assets
const SearchModal = ({ closeBtn, close }) => {
	const [searchText, setSearchText] = useState('');
	const { redirect } = useNavigator();

	const submit = (e) => {
		e.preventDefault();
		redirect('search', { term: searchText });
		close();
	}

	return (
		<>
			{closeBtn}

			<form
				onSubmit={submit}
				className="search-inputwrap">
				<Icon name="search" className="search-icon" />
				<input
					autoFocus
					placeholder="Arama Yapın"
					type="text"
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
					className="search-input"
					value={searchText} />

				<button
					type="button"
					onClick={() => {
						setSearchText('');
					}}
					className={classNames('search-clearbtn', { show: !!searchText.length })}>
					<Icon name="close" />
				</button>
			</form>
		</>
	)
}

SearchModal.propTypes = {
	closeBtn: PropTypes.node,
	close: PropTypes.func,
	icon: PropTypes.string,
	title: PropTypes.string,
	options: PropTypes.array,
	message: PropTypes.string,
	wysiwyg: PropTypes.bool,
	hideCloseBtn: PropTypes.bool,
}

SearchModal.modalConfig = {
	containerClass: 'mobile-full modal-search',
}

export default SearchModal